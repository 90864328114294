import React from 'react'
import Header from './header'
import Footer from './footer'

import '../styles/global.scss'
import layoutStyles from './layout.module.scss'

const Layout = (props) => {
    return(
        <div className={layoutStyles.main}>
            <div className={layoutStyles.wrapper}>
                <div className={layoutStyles.headerContent}>
                    <Header />
                        {props.children}
                </div>
                <Footer/>
            </div>
        </div>
        
    )
}

export default Layout