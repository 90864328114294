import React from 'react'
import { Link } from 'gatsby'

import headerStyles from './header.module.scss'

const Header = () => {
    return(
        <div>
            <ul className={headerStyles.navMenu}>
                <li><Link to="/" activeClassName={headerStyles.activeNavItem}>Home</Link></li>
                <li><Link to="/portfolio" activeClassName={headerStyles.activeNavItem}>Portfolio</Link></li>
            </ul>
        </div>
    )
    
}

export default Header