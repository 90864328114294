import React from 'react'
import Layout from '../components/layout'

import portfolioStyles from './portfolio.module.scss'

const Portfolio = () => {
    return(
        <Layout>
            <p style={{ marginTop: `1em`}}>A collection of websites created for clients.</p>
            <div>
                <a href="https://lanpcempco.org" target="_blank" rel="noreferrer noopener">
                    <div style={{ border: `dashed 2px lightgray`, padding: `0.4em 2em`}}>
                        <h3 style={{ marginTop: `1em`, marginBottom: `.8em`, color: `steelblue` }}>LANPCEMPCO</h3>
                        <p style={{ color: `#252525`, marginBottom: `0.2em` }}>Bulletin board type website for a local exclusive cooperative.</p>
                        <p style={{ color: `#777`, fontSize: `0.8rem` }}><em>Next.js, Netlify, Contentful</em></p>
                    </div>
                </a>
                <a href="https://ling-cookbook.netlify.app" target="_blank" rel="noreferrer noopener">
                    <div style={{ border: `dashed 2px lightgray`, padding: `0.4em 2em`, marginTop: `0.5em`}}>
                        <h3 style={{ marginTop: `1em`, marginBottom: `.8em`, color: `steelblue` }}>Ling's Cookbook</h3>
                        <p style={{ color: `#252525`, marginBottom: `0.2em` }}>Recipe collection website that features a checklist for ingredients and procedure.</p>
                        <p style={{ color: `#777`, fontSize: `0.8rem` }}><em>Next.js, Netlify, Prismic.io, Tailwind CSS</em></p>
                    </div>
                </a>
                <a href="https://bridgetangonan.com" target="_blank" rel="noreferrer noopener">
                    <div style={{ border: `dashed 2px lightgray`, padding: `0.4em 2em`, marginTop: `0.5em`}}>
                        <h3 style={{ marginTop: `1em`, marginBottom: `.8em`, color: `steelblue` }}>Bridge Tangonan's Blog</h3>
                        <p style={{ color: `#252525`, marginBottom: `0.2em` }}>My mother's personal blog.</p>
                        <p style={{ color: `#777`, fontSize: `0.8rem` }}>Next.js, Netlify, Contentful, Tailwind CSS</p>
                    </div>
                </a>
            </div>
        </Layout>
    )
}

export default Portfolio