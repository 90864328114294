import React from 'react'
import { useStaticQuery } from 'gatsby'

import footerStyles from './footer.module.scss'

import githubIcon from '../assets/github-icon.png'
import linkedInIcon from '../assets/linkedin-icon.png'
import twitterIcon from '../assets/twitter-icon.png'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    github
                    linkedin
                    twitter
                    }
                }
            }
        `)

    return(
        <div className={footerStyles.footer} style={{ marginTop: `2em`}}>
            <div className={footerStyles.socialSection}>
                <ul className={footerStyles.socialLinks}>
                    <li><a href={`https://github.com/${data.site.siteMetadata.github}`} rel="noopener noreferrer" target="_blank"><img src={githubIcon} className={footerStyles.iconSize}/></a></li>
                    <li><a href={`https://linkedin.com/in/${data.site.siteMetadata.linkedin}`} rel="noopener noreferrer" target="_blank"><img src={linkedInIcon} className={footerStyles.iconSize}/></a></li>
                    <li><a href={`https://twitter.com/${data.site.siteMetadata.twitter}`} rel="noopener noreferrer" target="_blank"><img src={twitterIcon} className={footerStyles.iconSize}/></a></li>
                </ul>
            </div>
            <p>Tim Tangonan © {new Date().getFullYear()} | Created using Gatsby, Github, Contentful, and Netlify.</p>
        </div>
    )
}

export default Footer